<template>
  <div>
    <div>
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        Sekolah
      </span>
      <small class="text-subtitle-2">Halaman sekolah</small>
    </div>
    <div class="mt-6">
      <v-row class="justify-space-between align-center my-6">
        <v-col
          md="4"
          cols="12"
          class=""
        >
          <div>
            <v-text-field
              v-model="search"
              label="Cari Sekolah"
              outlined
              dense
              class="white"
              hide-details="none"
              @change="searchHandler($event)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          md="8"
          cols="12"
          class=""
        >
          <v-row class="justify-md-end">
            <v-col
              md="4"
              cols="12"
            >
              <div class="d-xl-flex d-lg-flex d-md-flex d-block">
                <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Tingkat:</span>
                <v-autocomplete
                  label="Semua"
                  dense
                  :items="levels"
                  item-text="name"
                  item-value="uuid"
                  outlined
                  class="white"
                  hide-details="none"
                  clearable
                  @change="filterByLevel($event)"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <div class="d-xl-flex d-lg-flex d-md-flex">
                <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Urutkan:</span>
                <v-autocomplete
                  label="Semua"
                  dense
                  :items="sorts"
                  outlined
                  class="white"
                  hide-details="none"
                  clearable
                  @change="filterBySort($event)"
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="!isLoadingCard">
        <v-row>
          <v-col
            v-for="school in schools"
            :key="school.name"
            md="4"
            cols="12"
          >
            <v-card
              :to="{ name: 'school-headdepartment-detail', params: {uuid: school.uuid} }"
              height="290"
            >
              <v-img
                height="150"
                :src="school.image"
              ></v-img>
              <v-card-text>
                <h3 class="font-weight-bold black--text mb-2">
                  {{ school.name }}
                </h3>
                <div class="d-flex mb-2">
                  <div class="d-flex align-center">
                    <v-img
                      :src="icons.iconLocation"
                      width="20"
                    ></v-img> <span
                      class="ml-4"
                    >{{ school.place.address }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="d-flex align-center">
                    <v-img
                      :src="icons.iconClip"
                      width="20"
                    ></v-img> <span class="ml-4">www.google.com</span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          class="mb-5"
        >
          <v-col
            v-for="i,index in 6"
            :key="index"
            md="4"
            cols="12"
          >
            <v-skeleton-loader
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="isEmpty"
        class="d-flex justify-center"
      >
        <div

          class="pa-4 text-center"
        >
          <v-img
            src="@/assets/images/vector/empty.svg"
            class="mx-8 my-7"
          ></v-img>
          <p class="my-7">
            Belum ada data sekolah
          </p>
        </div>
      </div>
      <div class="mt-6">
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
          @change="paginationHandler"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import iconClip from '@/assets/icons/clip.svg'
import iconLocation from '@/assets/icons/location.svg'

export default {
  name: 'SchoolData',
  data() {
    return {
      search: '',
      sorts: ['Semua', 'Terbaru', 'Terlama'],
      sort: '',
      levels: ['Semua', 'SD', 'SMP'],
      level: '',
      icons: {
        iconLocation,
        iconClip,
      },
      service: 'school',
      page: 1,
      isEmpty: false,
      schools: [],
      totalPages: 0,
      province_uuid: '',
      isLoadingCard: true,
    }
  },
  watch: {
    page: {
      handler() {
        this.isLoadingCard = true
        this.listSchool()
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          this.isLoadingCard = true
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.listSchool()
          }, 500)
        }
      },
    },
    level: {
      handler() {
        this.isLoadingCard = true
        this.listSchool()
      },
    },
    sort: {
      handler() {
        this.isLoadingCard = true
        this.listSchool()
      },
    },
  },
  beforeMount() {
    this.getUuid()
  },
  mounted() {
    this.listSchool()
  },
  methods: {
    async listSchool(params = {}) {
      if (!this.regency_uuid) {
        await this.$services.ApiServices.list(this.service, {
          ...params,
          province_uuid: this.province_uuid,
          per_page: '6',
          jenjang: this.level,
          sort: this.sort,
          search: this.search,
        }).then(
          ({ data }) => {
            this.schools = data.data
            this.isEmpty = false
            if (this.schools.length < 1) {
              this.isEmpty = true
            }
            this.isLoadingCard = false
            this.totalPages = data.meta.last_page
          },
          err => {
            console.error(err)
          },
        )
      } else {
        await this.$services.ApiServices.list(this.service, {
          ...params,
          regencies_uuid: this.regency_uuid,
          per_page: '6',
          jenjang: this.level,
          sort: this.sort,
          search: this.search,
        }).then(
          ({ data }) => {
            this.schools = data.data
            this.isEmpty = false
            if (this.schools.length < 1) {
              this.isEmpty = true
            }
            this.isLoadingCard = false
            this.totalPages = data.meta.last_page
          },
          err => {
            console.error(err)
          },
        )
      }
    },
    getUuid() {
      this.details = JSON.parse(localStorage.getItem('user')).user.details
      this.details.forEach(el => {
        if (el.regencies !== null) {
          this.regency_uuid = el.regencies.uuid
        } else {
          this.province_uuid = el.province.uuid
        }
      })
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    filterBySort(data) {
      if (data === 'Terbaru') {
        this.sort = 'asc'
      } else if (data === 'Terlama') {
        this.sort = 'desc'
      } else if (data === 'Semua') {
        this.sort = ''
      }
    },
    filterByLevel(data) {
      if (data === 'SD') {
        this.level = 'SD'
      } else if (data === 'SMP') {
        this.level = 'SMP'
      } else if (data === 'Semua') {
        this.level = ''
      }
    },
  },
}
</script>

<style>
.v-card__text span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
